<template>
  <div class="app">
    <nav class="webNav">
      <div class="navContent">
        <div class="logoWrap">
          <img src="./assets/images/webIcon.png" alt="">
          <h3>全民藏书</h3>
        </div>
        <ul class="navList">
          <li class="navItem" v-for="(item,index) in navList" :key="index" @click="tabHandler(index)">
            <a href="javaScript:;" class="navText" :class="changeOrange == index ? 'navActive' : '' " >{{item.name}}</a>
          </li>
        </ul>
      </div>
    </nav>
    <Swiper id="swiperBox" v-bind:options="swiperOption" ref="mySwiper">
      <SwiperSlide class="webBg">
        <div class="indexWrap">
          <div class="indexBg"></div>
          <img src="./assets/images/newProduct.png" alt="" class="indexImg">
          <div class="indexTitle">

            <h3>百家藏书，藏百家书，</h3>
            <h3>纳古今贤。</h3>
            <p>一款专业的藏书管理小程序</p>
          </div>
          <div class="ewmWrap">
            <div class="ewmBox"></div>
            <h3 class="ewmTip">微信扫一扫 或 小程序搜索【 百家藏书】</h3>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide class="webBg">
        <div class="product1">
          <div class="productBg1"></div>
          <div class="bookRecommend">
            <div class="imgGroup">
              <img src="./assets/images/newBook.png" alt="" class="newPoster">
            </div>
            <div class="productText1">
              <h3>书籍推荐</h3>
              <h4>精挑细选，请君一览</h4>
              <p>月度新书推荐以及书籍销售排行推荐，使用列表排行形势展现，了解月度书籍状况。</p>
            </div>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide class="webBg"><div class="product1">
          <div class="productBg2">
            <div class="bambooTop"></div>
            <div class="bambooBottom"></div>
          </div>
          <div class="bookRecommend">
            <div class="imgGroup"></div>
            <div class="productText2">
              <h3>藏书</h3>
              <h4>一处静室，淡淡书香</h4>
              <p>模拟实时书架，对书籍进行多维度的管理。</p>
              <p>支持藏书搜索，快速检索藏书的相关信息。</p>
            </div>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide class="webBg">
        <div class="product1">
          <div class="productBg3">
            <div class="yunTop"></div>
            <div class="yunBottom"></div>
          </div>
          <div class="bookRecommend">
            <div class="productText3">
              <h3>书摘管理</h3>
              <h4>文学之美，点滴珍藏</h4>
              <p>高精度OCR文字识别，书摘录入更加高效便捷。且支持文字图片同时录入，让书摘更加丰富。</p>
            </div>
            <div class="studyManagement"></div>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide class="webBg">
        <div class="product1">
          <div class="productBg3">
            <div class="rightTree"></div>
          </div>
          <div class="bookRecommend">
            <div class="bookentry"></div>
            <div class="productText4">
              <h3>书籍录入</h3>
              <h4>指尖轻点，轻松入藏</h4>
              <p>扫描书籍ISBN、手动录入、书籍搜索录入，快速、便捷的找到书籍且录入书籍。</p>
            </div>

          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide class="webBg">
        <div class="heBgimg"></div>
        <div class="aboutWrap">
          <h3>关于我们</h3>
          <div class="aboutText">
            <p>“全民藏书” 是一个专注向广大藏书用户提供具有专业书目数据的藏书管理工具，集荐、藏、析于一体，致力于为用户提供方便快捷的藏书服务，在保护用户隐私的前提下，满足各年龄段书友的藏书需求。</p>
            <p>新书速递、月度销量、出版社书籍推荐、阅读喜好推荐，为你全方位推荐最值得阅读
  的经典佳作，丰富你的知识体系。</p>
            <p>“数字化”扫码录入、“便捷式”在线搜索书籍、“个性化”自定义书架名称，多维度管理
  你的实体图书、书架，为用户提供极简的藏书体验。</p>
            <p>藏书统计，对用户的藏书历史进行多维度的统计分析，便于用户快速了解当前藏书情
  况，并对分析结果提供各类可视化分析呈现。</p>
          </div>

        </div>
      </SwiperSlide>
      <SwiperSlide class="webBg">
        <div class="contactWrap"></div>
        <div class="aboutWrap">
          <h3>联系我们</h3>
          <div class="contact">
            <ul class="contactIcon">
              <li>
                <img src="./assets/images/address.png" alt="">
                <p>地址：北京市海淀区花园路13号11栋231</p>
              </li>
              <li>
                <img src="./assets/images/youbian.png" alt="">
                <p>邮编：100088</p>
              </li>
              <li>
                <img src="./assets/images/telephone.png" alt="">
                <p>电话：010-62394820</p>
              </li>
              <li>
                <img src="./assets/images/email.png" alt="">
                <p>邮箱：service@startechsoft.cn</p>
              </li>
            </ul>
          </div>

        </div>
      </SwiperSlide>
    </Swiper>
    <div class="swiper-pagination"  slot="pagination"></div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";

export default {
  name: "app",
  data() {
    return {
      navList:[
        {name:'首页'},
        {name:'产品介绍'},
        {name:'关于我们'},
        {name:'联系我们'}
      ],
      changeOrange:0,
      swiperOption: {
        notNextTick: true, //notNextTick是一个组件自有属性，如果notNextTick设置为true，组件则不会通过NextTick来实例化swiper，也就意味着你可以在第一时间获取到swiper对象，假如你需要刚加载遍使用获取swiper对象来做什么事，那么这个属性一定要是true
        direction: "vertical", //垂直方向移动
        grabCursor: true, //鼠标覆盖Swiper时指针会变成手掌形状，拖动时指针会变成抓手形状
        setWrapperSize: true, //Swiper使用flexbox布局(display: flex)，开启这个设定会在Wrapper上添加等于slides相加的宽或高，在对flexbox布局的支持不是很好的浏览器中可能需要用到。
        autoHeight: true, //自动高度。设置为true时，wrapper和container会随着当前slide的高度而发生变化
        slidesPerView: 1, //设置slider容器能够同时显示的slides数量(carousel模式)。可以设置为数字（可为小数，小数不可loop），或者 'auto'则自动根据slides的宽度来设定数量。loop模式下如果设置为'auto'还需要设置另外一个参数loopedSlides。
        mousewheel: true, //开启鼠标滚轮控制Swiper切换。可设置鼠标选项，默认值false
        mousewheelControl: true, //同上
        height: window.innerHeight, // 高度设置，占满设备高度
        resistanceRatio: 0, //抵抗率。边缘抵抗力的大小比例。值越小抵抗越大越难将slide拖离边缘，0时完全无法拖离。本业务需要
        observeParents: true, //将observe应用于Swiper的父元素。当Swiper的父元素变化时，例如window.resize，Swiper更新
        pagination: {
            el: '.swiper-pagination',
            bulletClass: 'my-bullet', //自定义分页器样式
            bulletActiveClass: 'my-bullet-active', //自定义当前选中样式
            clickable: true, //点击切换轮播
        },
        on: {
        //监听滑动切换事件，返回swiper对象
          slideChange: () => {
            let swiper = this.$refs.mySwiper.$swiper.activeIndex;
            console.log(swiper)
            if( swiper == 1 || swiper == 2 || swiper == 3 || swiper == 4){
              this.changeOrange = 1
            }else if( swiper == 5 ){
              this.changeOrange = 2;
            }else if( swiper == 6 ){
              this.changeOrange = 3;
            }else{
              this.changeOrange = 0;
            }
          }
        },

      },
    };
  },
  methods:{
    tabHandler(i){
      this.changeOrange = i;
      if( i == 2 ){
        this.$refs.mySwiper.$swiper.slideTo(5, 500, false)
      }else if( i == 3 ){
        this.$refs.mySwiper.$swiper.slideTo(6, 500, false)
      }else{
        this.$refs.mySwiper.$swiper.slideTo(i, 500, false)
      }
    },
  },
  created() {
    //从后台获取数据
    // this.list = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
  },
 // 如果你需要得到当前的swiper对象来做一些事情，你可以像下面这样定义一个方法属性来获取当前的swiper对象，同时notNextTick必须为true
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper;
    }
  },
  mounted() {
  //  this.swiper.slideTo(3, 1000, false); //手动跳到指定页
  },
  components: {
    Swiper,
    SwiperSlide
  }
};
</script>

<style>
.webNav{
  width: 100%;
  height: 84px;
  line-height: 84px;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10;
}
.navContent{
  width: 62.5%;
  height: 84px;
  margin: 0 auto;
}
.logoWrap{
  float: left;
  height: 84px;
  line-height: 84px;
}
.logoWrap img{
  float: left;
  width: 38px;
  height: 38px;
  margin-top: 24px;
}
.logoWrap h3{
  float: left;
  margin-left: 20px;
  font-family: STSongti-SC-Bold, STSongti-SC;
  color: #333;
  font-size: 23px;
}
.navList{
  float: right;
  height: 84px;
  line-height: 84px;
}
.navItem{
  float: left;
  margin-left: 90px;
}
.navText{
  font-size: 18px;
  color: #333;
  font-family: STSongti-SC-Bold, STSongti-SC;
  font-weight: bold;
}
.navActive{
  color: #E66951;
  border-bottom: 4px solid #E66951;
}
.swiper-pagination{
  width: 26px;
  height: 220px;
  position: fixed;
  right: 65px;
  top: 50%;
  /* background: yellowgreen; */
  margin-top: -110px;
}
.my-bullet{
    display: block;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    margin: 20px 0;
    background: rgba(230, 105, 81, 0.4);
}
.my-bullet-active{
    width: 26px;
    height: 25px;
    background: url(assets/images/pageIcon.png);
    background-size: cover;
    margin: 20px 0;
    border-radius: 0;
    margin-left: -8px;
}
.webBg{
  background: url(assets/images/webBg.png);
  width: 100%;
  height: 100%;
  position: fixed;
  background-size: 100% 100%;
}
.indexImg{
  position: absolute;
  left: 270px;
  bottom: -75px;
  width: 370px;
  height: 643px;

}
.indexBg{
  width: 100%;
  height: 86%;
  background: url(assets/images/indexBg.png);
  background-size: 100% 100%;
  position: absolute;
  left: 0;
  bottom: 0;
}
.ewmWrap{
  position: absolute;
  right: 274px;
  bottom: 144px;
}
.ewmBox{
  width: 218px;
  height: 215px;
  margin: 0 auto;
  background: url(assets/images/indexEwm.png);
  background-size: 100% 100%;
}
.ewmTip{
  color: #666;
  font-size: 15px;
  margin-top: 19px;
  font-family: STSongti-SC-Regular, STSongti-SC;
}
.indexTitle{
  position: absolute;
  right: 270px;
  top: 140px;
}
.indexTitle h3{
  text-align: right;
  font-family: STSongti-SC-Black, STSongti-SC;
  color: #333;
  font-size: 45px;
}
.indexTitle p{
  font-size: 18px;
  color: #666;
  text-align: right;
  font-family: STSongti-SC-Regular, STSongti-SC;
  margin-top: 8px;
  margin-right: 50px;
}
.productBg1{
  width: 100%;
  height: 61.5%;
  background: url(assets/images/chanpinBg.png);
  background-size: 100% 100%;
  position: absolute;
  left: 0;
  bottom: 0;
}
.bookRecommend{
  position: relative;
  width: 62.5%;
  height: 600px;
  margin: 83px auto 0;
}
.productText1{
  width: 410px;
}
.imgGroup{
  float: left;
  width: 300px;
  height: 600px;
  background: url(assets/images/newProduct.png);
  background-size: 100% 100%;
}
.productText1{
  float: left;
}
.newPoster{
  display: block;
  width: 450px;
  margin-left: -15px;
  margin-top: 40px;
}
.productText1{
  margin-left: 128px;
  margin-top: 100px;
}
.productText1 h3{
  font-size: 45px;
  color: #E66950;
  font-family: STSongti-SC-Black, STSongti-SC;
}
.productText1 h4{
  color: #666666;
  font-size: 27px;
  font-family: STSongti-SC-Regular, STSongti-SC;
  margin-top: 10px;
}
.productText1 p{
  font-size: 27px;
  color: #666;
  line-height: 38px;
  font-family: STSongti-SC-Regular, STSongti-SC;
  margin-top: 70px;
}
.productBg2,.productBg3{
  width: 100%;
  height: 100%;
}
.bambooTop{
  width: 56%;
  height: 44.4%;
  position: absolute;
  top: 0;
  right: 0;
  background: url(assets/images/bambooTop.png);
  background-size: 100% 100%;
}
.bambooBottom{
  width: 32.8%;
  height: 40%;
  position: absolute;
  left: 0;
  bottom: 0;
  background: url(assets/images/bambooBottom.png);
  background-size: 100% 100%;
}
.productText2{
  float: left;
  margin-left: 60px;
  margin-top: 170px;
}
.productText2 h3{
  color: #E66951;
  font-size: 45px;
  font-family: STSongti-SC-Black, STSongti-SC;
}
.productText2 h4{
  color: #666666;
  font-size: 27px;
  font-family: STSongti-SC-Regular, STSongti-SC;
  margin-top: 15px;
  margin-bottom: 66px;
}
.productText2 p{
  color: #666666;
  font-size: 27px;
  font-family: STSongti-SC-Regular, STSongti-SC;
}
.yunTop{
  width: 15%;
  height: 10%;
  position: absolute;
  left: 80px;
  top: 80px;
  background: url(assets/images/xiangyun.png);
  background-size: 100% 100%;
}
.yunBottom{
  width: 15%;
  height: 10%;
  position: absolute;
  right: 85px;
  bottom: 92px;
  background: url(assets/images/xiangyun.png);
  background-size: 100% 100%;
}
.studyManagement{
  float: left;
  width: 300px;
  height: 600px;
  background: url(assets/images/newProduct.png);
  background-size: 100% 100%;
}
.productText3{
  float: left;
  width: 430px;
  margin-top: 107px;
  margin-right: 120px;
}
.productText3 h3{
  color:#EB6B53;
  font-size: 45px;
  font-family: STSongti-SC-Black, STSongti-SC;

}
.productText3 h4{
  color: #666666;
  font-size: 27px;
  font-family: STSongti-SC-Regular, STSongti-SC;
  margin-top: 15px;
}
.productText3 p{
  color: #666;
  font-size: 27px;
  font-family: STSongti-SC-Regular, STSongti-SC;
  margin-top: 63px;

}
.rightTree{
  width: 84%;
  height: 87.4%;
  position: absolute;
  right: 0;
  bottom: 30px;
  background: url(assets/images/zhuTree.png);
  background-size: 100% 100%;
}
.bookentry{
  float: left;
  width: 550px;
  height: 510px;
  background: url(assets/images/twoPhone.png);
  background-size: 100% 100%;
  margin-left: -70px;
  margin-right: 3%;
}
.productText4{
  float: left;
  width: 350px;
  margin-top: 124px;

}
.productText4 h3{
  color:#EB6B53;
  font-size:45px;
  font-family: STSongti-SC-Black, STSongti-SC;
}
.productText4 h4{
  color: #666;
  font-size: 27px;
  font-family: STSongti-SC-Regular, STSongti-SC;
  margin-top: 15px;
}
.productText4 p{
  color: #666;
  font-size: 27px;
  font-family: STSongti-SC-Regular, STSongti-SC;
  margin-top: 63px;
}
.heBgimg{
  width: 30%;
  height: 36%;
  position: absolute;
  right: 38px;
  top: 32%;
  background: url(assets/images/aboutUs.png);
  background-size: 100% 100%;
}
.aboutWrap{
  width: 58.3%;
  margin: 128px auto 0;
}
.aboutWrap h3{
  color: #EB6B53;
  font-size: 45px;
  text-align: center;
  font-family: STSongti-SC-Black, STSongti-SC;
}
.aboutText{
  margin-top: 50px;
}
.aboutText p{
  font-size: 21px;
  font-family: STSongti-SC-Regular, STSongti-SC;
  color: #666;
  text-indent: 2em;
  line-height: 30px;
}
.contactWrap{
  position: absolute;
  left: 0;
  bottom:160px;
  width: 100%;
  height: 47.7%;
  background: url(assets/images/contact.png);
  background-size: 100% 100%;
}
.contact{
  position: relative;
}
.contactIcon{
  margin-top: 80px;
}
.contactIcon li{
  height: 30px;
  line-height: 30px;
  margin-bottom: 27px;
}
.contactIcon li img{
  float: left;
  width: 26px;
  height: 26px;
  margin-right: 10px;
  margin-top: 4px;
}
.contactIcon li p{
  float: left;
  font-size: 21px;
  font-family: STSongti-SC-Regular, STSongti-SC;
  color: #666666;
  height: 30px;
  line-height: 30px;
}
</style>
