/*
	该文件是整个项目的的入口文件
*/ 
// 引入vue
import Vue from 'vue'
// 引入APP组件，它是所有组件的父组件
import App from './App.vue'
// 样式重置
import './assets/css/reset.css'
// 关闭vue的生产提示
Vue.config.productionTip = false

import vueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css/swiper.min.css';
Vue.use(vueAwesomeSwiper);
// 创建Vue实例对象——vm
new Vue({
	//将APP组件放入容器中 
  render: h => h(App),
}).$mount('#app')
